import posthog from "posthog-js"
import {
  AnalyticsPlatformDef,
  AnalyticsEvent,
} from "@hoppscotch/common/platform/analytics"

import { def as platformAuth, getUserFirebaseMetadata } from "./firebase/auth"

import {
  HoppAccentColor,
  HoppBgColor,
  settings$,
  settingsStore,
  getSettingSubject,
} from "@hoppscotch/common/newstore/settings"
import { isEqual } from "lodash-es"
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs"
import { platform } from "@hoppscotch/common/platform"

type SettingsCustomDimensions = {
  usesProxy: boolean
  usesExtension: boolean
  syncCollections: boolean
  syncEnvironments: boolean
  syncHistory: boolean
  usesBg: HoppBgColor
  usesAccent: HoppAccentColor
  usesTelemetry: boolean
}

export function addPosthogIgnoreClass(element: HTMLElement) {
  element.classList.add("ph-no-capture")
}

export function initAnalytics() {
  // Don't turn on Posthog if the keys are not defined
  if (
    !import.meta.env.VITE_APP_POSTHOG_API_HOST ||
    !import.meta.env.VITE_APP_POSTHOG_API_KEY
  )
    return

  posthog.init(import.meta.env.VITE_APP_POSTHOG_API_KEY, {
    api_host: import.meta.env.VITE_APP_POSTHOG_API_HOST,
    autocapture: false,
    capture_pageview: false,
  })

  posthog.onFeatureFlags(() => {
    if (posthog.getFeatureFlag("signup-conversion") === "test") {
      if (platform.platformFeatureFlags.workspaceSwitcherLogin)
        platform.platformFeatureFlags.workspaceSwitcherLogin.value = true
    }
  })

  getSettingSubject("TELEMETRY_ENABLED").subscribe((enabled) => {
    if (enabled) posthog.opt_in_capturing()
    else posthog.opt_out_capturing()

    const currentUser = platformAuth.getCurrentUser()
    const userMeta = getUserFirebaseMetadata()

    const creationTimeString = userMeta?.creationTime
    const lastSignInTimeString = userMeta?.lastSignInTime

    if (currentUser && currentUser?.uid != posthog.get_distinct_id()) {
      posthog.identify(currentUser.uid, {
        email: currentUser.email,
        creationTime: creationTimeString
          ? new Date(creationTimeString).toISOString()
          : undefined,
        lastSignInTime: lastSignInTimeString
          ? new Date(lastSignInTimeString).toISOString()
          : undefined,
      })
    }
  })

  setupIdentityListeners()
  setupAuthEventListeners()
  setupSettingsListeners()
}

function setupIdentityListeners() {
  // Emit to posthog the identity info if the info has changed
  combineLatest([
    platformAuth.getCurrentUserStream(),
    getSettingSubject("TELEMETRY_ENABLED"),
  ])
    .pipe(
      // Only emit if the telemetry is enabled
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter(([_user, telemetryEnabled]) => telemetryEnabled),

      // Map the user data into the format posthog wants
      map(([user]) => {
        if (!user) return null

        const meta = getUserFirebaseMetadata()

        const creationTimeString = meta?.creationTime
        const lastSignInTimeString = meta?.lastSignInTime

        return {
          uid: user.uid,
          email: user.email,
          creationTime: creationTimeString
            ? new Date(creationTimeString).toISOString()
            : undefined,
          lastSignInTime: lastSignInTimeString
            ? new Date(lastSignInTimeString).toISOString()
            : undefined,
        }
      }),
      // Only emit if the identity data has changed
      distinctUntilChanged((a, b) => isEqual(a, b))
    )
    .subscribe((data) => {
      // If the telemetry is not enabled, don't send the identity info
      if (!settingsStore.value.TELEMETRY_ENABLED) return

      // If the user is not logged in, reset the user identifier in posthog
      if (!data) {
        posthog.reset()
        return
      }

      // Send the identity info to posthog
      posthog.identify(data.uid, {
        email: data.email,
        creationTime: data.creationTime,
        lastSignInTime: data.lastSignInTime,
      })
    })
}

function setupAuthEventListeners() {
  platformAuth.getAuthEventsStream().subscribe((ev) => {
    // Do not emit events if the telemetry is not enabled
    if (!settingsStore.value.TELEMETRY_ENABLED) return

    if (ev.event === "login") {
      posthog.capture("USER_LOGGED_IN", {
        uid: ev.user.uid,
        email: ev.user.email,
      })
    } else if (ev.event === "logout") {
      posthog.capture("USER_LOGGED_OUT")
    }
  })
}

function setupSettingsListeners() {
  settings$
    .pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      debounceTime(2000)
    )
    .subscribe((settings) => {
      if (!settings.TELEMETRY_ENABLED) return

      const conf: SettingsCustomDimensions = {
        usesProxy: settings.CURRENT_INTERCEPTOR_ID === "proxy",
        usesExtension: settings.CURRENT_INTERCEPTOR_ID === "extension",
        syncCollections: settings.syncCollections,
        syncEnvironments: settings.syncEnvironments,
        syncHistory: settings.syncHistory,
        usesAccent: settings.THEME_COLOR,
        usesBg: settings.BG_COLOR,
        usesTelemetry: settings.TELEMETRY_ENABLED,
      }

      posthog.people.set(conf)
    })
}

export function logEvent(ev: AnalyticsEvent) {
  posthog.capture(ev.type, ev)
}

export function logPageView(pagePath: string) {
  posthog.capture("PAGE_VIEW", {
    page_path: pagePath,
  })
}

export const def: AnalyticsPlatformDef = {
  initAnalytics,
  logEvent,
  logPageView,
}
