import { HoppModule } from "@hoppscotch/common/modules"
import { defineActionHandler } from "@hoppscotch/common/helpers/actions"
import { HoppFooterMenuItem } from "@hoppscotch/common/platform/ui"
import { HoppSupportOptionsMenuItem } from "@hoppscotch/common/platform/ui"
import IconMessageCircle from "~icons/lucide/message-circle"
import { markRaw } from "vue"
import { GeneralSpotlightSearcherService } from "@hoppscotch/common/services/spotlight/searchers/general.searcher"
import { getService } from "@hoppscotch/common/modules/dioc"
import { useI18n } from "@hoppscotch/common/composables/i18n"

const showChat = () => {
  ;(window as any).$crisp.push([
    "do",
    "chat:show",
    (window as any).$crisp.push(["do", "chat:open"]),
  ])
}

export const crispModule: HoppModule = {
  onVueAppInit() {
    ;(window as any).$crisp = []
    ;(window as any).CRISP_WEBSITE_ID = "3ad30257-c192-4773-955d-fb05a4b41af3"

    const d = document
    const s = d.createElement("script")

    s.src = "https://client.crisp.chat/l.js"
    s.async = true
    d.getElementsByTagName("head")[0].appendChild(s)
    ;(window as any).$crisp.push(["do", "chat:hide"])
    ;(window as any).$crisp.push([
      "on",
      "chat:closed",
      () => {
        ;(window as any).$crisp.push(["do", "chat:hide"])
      },
    ])
  },
  onRootSetup() {
    const t = useI18n()

    document.addEventListener("readystatechange", () => {
      if (document.readyState === "complete") {
        const generalSpotlightSearcher = getService(
          GeneralSpotlightSearcherService
        )

        generalSpotlightSearcher.addCustomEntries({
          chat_with_us: {
            text: t("spotlight.general.chat"),
            alternates: ["chat", "support", "hoppscotch"],
            icon: markRaw(IconMessageCircle),
            action() {
              showChat()
            },
          },
        })
      }
    })

    defineActionHandler("flyouts.chat.open", () => {
      showChat()
    })
  },
}

export const chatWithUsFooterMenuItem: HoppFooterMenuItem = {
  id: "chat_with_us",
  text: (t) => t("app.chat_with_us"),
  icon: IconMessageCircle,
  action: {
    type: "custom",
    do() {
      showChat()
    },
  },
}

export const chatWithUsSupportOptionMenuItem: HoppSupportOptionsMenuItem = {
  id: "chat_with_us",
  text: (t) => t("app.chat_with_us"),
  subtitle: (t) => t("support.chat"),
  icon: markRaw(IconMessageCircle),
  action: {
    type: "custom",
    do() {
      showChat()
    },
  },
}
