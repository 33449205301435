import algoliasearch from "algoliasearch/lite"
import { type SearchIndex } from "algoliasearch/lite"
import { Service } from "dioc"
import { Ref, computed, effectScope, markRaw, ref, watch } from "vue"
import { z } from "zod"

import { getI18n } from "@hoppscotch/common/modules/i18n"
import { platform } from "@hoppscotch/common/platform"
import {
  SpotlightSearcher,
  SpotlightSearcherResult,
  SpotlightSearcherSessionState,
  SpotlightService,
} from "@hoppscotch/common/services/spotlight"

import IconArrowUpRight from "~icons/lucide/arrow-up-right"

const documentationResultMetaSchema = z.object({
  url: z.string(),
})

/**
 * a very simple function to calculate the weight of a given value, gives more weight when the value is smaller.
 */
const calculateWeight = (val: number) => {
  const initialWeight = 1
  const decayFactor = 0.1
  return Math.max(initialWeight - val * decayFactor, 0)
}

/**
 * This function calculates the score of a given result based on the ranking info provided by algolia.
 * this is not at all perfect. but a hacked together solution to get some sort of ranking that can be compared against minisearch score.
 */
const getScoreFromRankingInfo = (rankingInfo: {
  nbTypos: number
  proximityDistance?: number
  nbExactWords: number
}) => {
  const { nbTypos, proximityDistance, nbExactWords } = rankingInfo

  const score =
    calculateWeight(nbTypos) +
    (proximityDistance ? calculateWeight(proximityDistance) : 0) +
    nbExactWords

  return score
}

/**
 * This searcher is responsible for searching hoppscotch docs using algolia.
 *
 * NOTE: Initializing this service registers it as a searcher with the Spotlight Service.
 */
export class DocumentationSearcherService
  extends Service
  implements SpotlightSearcher
{
  public static readonly ID = "DOCUMENATION_SPOTLIGHT_SEARCHER_SERVICE"

  private t = getI18n()

  public searcherID = "documentation"
  public searcherSectionTitle = this.t("tab.documentation")

  private readonly spotlight = this.bind(SpotlightService)

  private index!: SearchIndex

  override onServiceInit() {
    if (
      !import.meta.env.VITE_ALGOLIA_APP_ID ||
      !import.meta.env.VITE_ALGOLIA_API_KEY ||
      !import.meta.env.VITE_ALGOLIA_INDEX_NAME
    ) {
      console.warn(
        "Algolia Credentials not provided. searcher will not be registered"
      )

      return
    }

    const searchClient = algoliasearch(
      import.meta.env.VITE_ALGOLIA_APP_ID,
      import.meta.env.VITE_ALGOLIA_API_KEY
    )

    this.index = searchClient.initIndex(import.meta.env.VITE_ALGOLIA_INDEX_NAME)

    this.spotlight.registerSearcher(this)
  }

  createSearchSession(
    query: Readonly<Ref<string>>
  ): [Ref<SpotlightSearcherSessionState>, () => void] {
    const loading = ref(false)
    const results = ref<SpotlightSearcherResult[]>([])

    const scopeHandle = effectScope()

    type Level = "lvl1" | "lvl2" | "lvl3" | "lvl4" | "lvl5"

    type Hit = {
      url: string
      hierarchy: Record<Level, string | null>
    }

    scopeHandle.run(() => {
      watch(
        query,
        async (query) => {
          // reason for asserting non null for this.index: when used as a search service, createSearchSession method won't be called without the service being registered, because of the check in the constructor. so this.index won't be undefined.
          const { hits } = await this.index!.search<Hit>(query, {
            getRankingInfo: true,
            hitsPerPage: 5,
          })

          results.value = hits.map((hit) => ({
            id: hit.objectID,
            icon: markRaw(IconArrowUpRight),
            text: {
              type: "text",
              text: Object.values(hit.hierarchy)
                .filter((v): v is string => v !== null)
                // removing the Documentation prefix )
                .slice(1),
            },
            score: hit._rankingInfo
              ? getScoreFromRankingInfo(hit._rankingInfo) * 0.2
              : 0,
            meta: {
              additionalInfo: {
                url: hit.url,
              },
            },
          }))
        },
        {
          immediate: true,
        }
      )
    })

    const onSessionEnd = () => {
      scopeHandle.stop()
    }

    const resultObj = computed<SpotlightSearcherSessionState>(() => ({
      loading: loading.value,
      results: results.value,
    }))

    return [resultObj, onSessionEnd]
  }

  onResultSelect = (result: SpotlightSearcherResult) => {
    const additionalInfo = result.meta?.additionalInfo

    const parseResult = documentationResultMetaSchema.safeParse(additionalInfo)

    if (!parseResult.success) {
      return
    }

    platform.io.openExternalLink(parseResult.data.url)
  }
}
