import axios, { AxiosError } from "axios"
import * as E from "fp-ts/Either"

const endpoint = `${import.meta.env.VITE_BACKEND_API_URL}/newsletter/subscriber`

export async function subscribeToNewsletter(authIDToken: string) {
  try {
    const res = await axios.post(endpoint, undefined, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authIDToken}`,
      },
    })

    return res.status === 201
      ? E.right(true)
      : E.left("SOMETHING_WENT_WRONG" as const)
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 409) {
      return E.left("ALREADY_SUBSCRIBED" as const)
    }

    return E.left("SOMETHING_WENT_WRONG" as const)
  }
}

export async function getSubscriptionDetails(authIDToken: string) {
  try {
    const response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authIDToken}`,
      },
    })

    const isEnabled = response.data.status === "enabled"

    return E.right({
      enabled: isEnabled,
    })
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      return E.left("NOT_SUBSCRIBED" as const)
    }

    return E.left("SOMETHING_WENT_WRONG" as const)
  }
}

export async function deleteNewsletterSubscription(authIDToken: string) {
  try {
    await axios.delete(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authIDToken}`,
      },
    })

    return E.right(true)
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 400) {
      return E.left("NOT_SUBSCRIBED" as const)
    }

    return E.left("SOMETHING_WENT_WRONG" as const)
  }
}
